export const initializeSlider = (setRenderSlider, hideOffSceenSlide) => {
  setTimeout(() => {
    setRenderSlider(true);
    if (hideOffSceenSlide) {
      document
        .querySelectorAll('.slick-slide[data-index="3"]')
        .forEach(slide => {
          slide.style.opacity = 0.3;
        });
    }
  }, 50);
};

export const hideOffscreenSlides = () => {
  const slides = document.querySelectorAll('.slick-slide');
  slides.forEach(slide => {
    const rect = slide.getBoundingClientRect();
    if (rect.left < 0 || rect.right > window.innerWidth) {
      slide.style.opacity = 0.3;
    } else {
      slide.style.opacity = 1;
    }
  });
};

export const defaultConfig = {
  dots: false,
  arrows: false,
  controls: true,
  infinite: true,
  centerMode: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  touchThreshold: 100,
  useTransform: false,
  afterChange: () => hideOffscreenSlides(),
};
