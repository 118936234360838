import { useState } from 'react';
import { navigate } from 'gatsby';

const useSwiperDirection = () => {
  const [xMousePosition, setXMousePosition] = useState(null);
  const [yMousePosition, setYMousePosition] = useState(null);

  const handleClick = (e, url, setModal) => {
    e.stopPropagation();
    if (xMousePosition !== e.clientX || yMousePosition !== e.clientY) {
      e.preventDefault();
      return;
    }
    if (url) navigate(url);
    if (setModal) setModal(true);
  };

  const handleMouseDown = e => {
    e.preventDefault();
    setXMousePosition(e.clientX);
    setYMousePosition(e.clientY);
  };
  return { handleClick, handleMouseDown };
};

export default useSwiperDirection;
