import React from 'react';
import useSwipe from '../../hooks/useSwipe';
import Badge from '../../molecules/Badge/Badge';
import ClickableElement from '../../molecules/ClickableElement/ClickableElement';
import { colors } from '../../styles/atoms/colors';
import Image from '../../utils/OptimizedImage';
import getReadTimeLabel from '../../utils/getReadTimeLabel';
import { isExternalLink } from '../../utils/links/isExternalLink';
import { tagColor } from '../ListingGenerator/ResourcesListing/utils/tagColors';
import {
  Button,
  ContentWrapper,
  Excerpt,
  Heading,
  MetaLabel,
  ResourceCardStyle,
  TagContainer,
  Thumbnail,
} from './styles/ResourceCard.styles';

const getWordCount = postBody => {
  return postBody.split(' ').length;
};

const ResourceCard = ({
  excerpt,
  image,
  link,
  metaLabel,
  postBody,
  subhead,
  tags,
  title,
  wordCount,
}) => {
  const { handleClick, handleMouseDown } = useSwipe();
  const resourceWordCount = postBody ? getWordCount(postBody) : wordCount;
  const modalRef = link?.includes('youtube.com') && {
    heading: title,
    subheading: excerpt,
    youtubeUrl: link,
    callToAction: {
      variant: 'primary',
      label: 'Request a demo',
      url: 'https://www.shopmonkey.io/demo',
    },
  };

  const getPreserveCasing = value => {
    // Return whether or not to prevent CSS capitalization
    switch (value) {
      case 'eBook':
        return true;

      default:
        return false;
    }
  };

  const getTagTitle = value => {
    switch (value) {
      case 'eBook':
        return 'eBook';

      case 'pdf resource':
        return 'PDF Resource';

      default:
        return value;
    }
  };

  return (
    <ResourceCardStyle className="resource-card">
      {modalRef ? (
        <ClickableElement
          url={link}
          openInNewTab={isExternalLink(link)}
          modal={modalRef}
        />
      ) : (
        <Button
          onClick={e => handleClick(e, link)}
          onMouseDown={e => handleMouseDown(e)}
          aria-label={title}
        />
      )}
      <Thumbnail>
        {image && (
          <Image
            src={image.image || ''}
            image={image.gatsbyImageData || ''}
            alt={title || 'Shopmonkey Resource'}
          />
        )}
      </Thumbnail>
      <ContentWrapper>
        <div className="body">
          <Heading>{title || 'Shopmonkey Resource'}</Heading>
          <Excerpt>
            {excerpt && excerpt}
            {subhead && <div dangerouslySetInnerHTML={{ __html: subhead }} />}
          </Excerpt>
        </div>
        <div className="footer">
          <TagContainer>
            {tags &&
              tags.map(tag => (
                <Badge
                  key={tag.id}
                  size="sm"
                  preserveCasing={getPreserveCasing(tag.name)}
                  badgeColor={
                    tagColor[tag.name.toLowerCase()]
                      ? tagColor[tag.name.toLowerCase()].bg
                      : colors.gray[50]
                  }
                  textColor={
                    tagColor[tag.name.toLowerCase()]
                      ? tagColor[tag.name.toLowerCase()].text
                      : colors.gray[800]
                  }
                >
                  {getTagTitle(tag.name)}
                </Badge>
              ))}
          </TagContainer>
          {metaLabel && <MetaLabel>{metaLabel}</MetaLabel>}
          {resourceWordCount > 0 && (
            <MetaLabel>{getReadTimeLabel(resourceWordCount)}</MetaLabel>
          )}
        </div>
      </ContentWrapper>
    </ResourceCardStyle>
  );
};

export default ResourceCard;
