import React from 'react';

import CallToAction from '../../molecules/CallToAction/CallToAction';
import LiteYoutube from '../Lite-Youtube/LiteYoutube';
import OptimizedImage from '../../utils/OptimizedImage';

// eslint-disable-next-line import/no-named-default
import { default as IconAtom } from '../../styles/atoms/icons';
import { colors } from '../../styles/atoms/colors';

import {
  Container,
  Overlay,
  Wrapper,
  Exit,
  Video,
  Content,
  Details,
  Icon,
  Heading,
  Subhead,
} from './styles/VideoModal.styled';

const VideoModal = ({ data, videoId, autoplay, trigger, setTrigger }) => {
  const { heading, subhead, callToAction, company } = data;

  return (
    <Container isOpen={trigger}>
      <Overlay aria-label="Exit" type="button" onClick={() => setTrigger(!trigger)} />
      <Wrapper isOpen={trigger}>
        <Exit aria-label="Exit" type="button" onClick={() => setTrigger(!trigger)}>
          <IconAtom id="FiX" color={colors.base.white} size={25} />
        </Exit>
        <Video>
          {trigger && <LiteYoutube autoplay={autoplay} videoId={videoId} />}
        </Video>
        <Content>
          <Details>
            {company?.lightLogo && (
              <Icon>
                <OptimizedImage
                  image={company?.lightLogo?.image?.gatsbyImageData}
                  alt={company?.lightLogo?.image?.alt}
                  title={company?.lightLogo?.image?.title}
                />
              </Icon>
            )}
            {heading && <Heading>{heading}</Heading>}
            {subhead && <Subhead>{subhead}</Subhead>}
          </Details>
          {callToAction?.url && (
            <CallToAction
              variant={callToAction?.variant}
              size={callToAction?.size}
              link={callToAction?.url}
              value={callToAction?.label}
              icon={callToAction?.icon}
              className="video-modal-cta"
            />
          )}
        </Content>
      </Wrapper>
    </Container>
  );
};

export default VideoModal;
