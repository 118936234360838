import React, { useState } from 'react';

import VideoModal from '../../components/VideoModal/VideoModal';
import { getYoutubeId } from '../../utils/getYoutubeId';

import { Clickable, ClickableLink } from './styles/ClickableElement.styled';

const ClickableElement = ({
  url,
  children,
  openInNewTab,
  className,
  style,
  modal,
  'aria-label': ariaLabel
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = event => {
    modal && event.preventDefault();
    modal && setModalOpen(true);
  };

  return (
    <>
      <Clickable
        className={className}
        style={style}
        onClick={event => handleClick(event)}
      >
        <ClickableLink to={url || '/'} openInNewTab={openInNewTab} aria-label={ariaLabel}>
          {children}
        </ClickableLink>
      </Clickable>
      {modal && (
        <VideoModal
          data={modal}
          videoId={getYoutubeId(modal?.youtubeUrl)}
          trigger={modalOpen}
          setTrigger={setModalOpen}
          autoplay
        />
      )}
    </>
  );
};

export default ClickableElement;
