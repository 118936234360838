import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Section from '../../../molecules/Section/Section';
import Slider from '../../../molecules/Slider/Slider';
import ResourceCard from '../../Cards/ResourcesCard';
import {
  Header,
  innerStyles,
  sectionStyles,
  sliderStyles,
  SliderWrapper,
} from '../styles/ResourcesSliderCardDeck.styled';
import {
  formatCaseStudy,
  formatVideoTemplate,
  formatDatoResource,
  formatBlogPost,
} from '../../ListingGenerator/ResourcesListing/utils/formatting';

const ResourcesSliderCardDeck = () => {
  const { resources, caseStudies, videoDetail, blogs } = useStaticQuery(query);

  const formattedCaseStudies = caseStudies.edges.map(caseStudy =>
    formatCaseStudy(caseStudy.node)
  );

  const formattedResources = resources.edges.map(datoResource =>
    formatDatoResource(datoResource.node)
  );

  const websiteVideos = videoDetail.edges.map(video =>
    formatVideoTemplate(video.node)
  );

  const formattedBlogs = blogs.edges.map(blog => formatBlogPost(blog.node));

  const orderedResources = [
    ...formattedResources,
    ...formattedCaseStudies,
    ...websiteVideos,
    ...formattedBlogs,
  ].sort((a, b) => {
    const date1 = new Date(a.publishDate ? a.publishDate : 0);
    const date2 = new Date(b.publishDate ? b.publishDate : 0);
    return date2 - date1;
  });

  return (
    <Section style={sectionStyles} innerStyle={innerStyles}>
      <Header>
        <h2>Recommended</h2>
      </Header>
      <SliderWrapper>
        <Slider styles={sliderStyles} hideOffSceenSlide>
          {orderedResources?.map(resource => (
            <ResourceCard
              excerpt={resource?.excerpt}
              image={resource?.image}
              key={resource?.id}
              link={resource?.url}
              metaLabel={resource?.metaLabel}
              postBody={resource?.postBody}
              tags={resource?.tags}
              title={resource?.name}
              wordCount={resource?.wordCount}
            />
          ))}
        </Slider>
      </SliderWrapper>
    </Section>
  );
};

const query = graphql`
  query {
    resources: allDatoCmsResource(
      sort: { fields: publishDate, order: DESC }
      filter: { isRecommended: { eq: true } }
    ) {
      edges {
        node {
          id
          title
          internalName
          isRecommended
          excerpt
          featuredImage {
            gatsbyImageData(
              imgixParams: {
                fm: "webp"
                auto: "compress"
                maxW: 1080
                fit: "clip"
                q: 35
              }
            )
          }
          publishDate
          slug
          announcement {
            id
            pageTitle
          }
          headHtml
          tags {
            name
          }
        }
      }
    }
    caseStudies: allDatoCmsCaseStudy(
      sort: { fields: publishDate, order: DESC }
      filter: { isRecommended: { eq: true } }
    ) {
      edges {
        node {
          ...datoCmsCaseStudy
          contentWordCounter
          aboutCompanyWordCounter
          publishDate
          meta {
            publishedAt
          }
        }
      }
    }
    videoDetail: allDatoCmsPageTemplateVideoDetail(
      sort: { fields: meta___publishedAt, order: DESC }
      filter: { isRecommended: { eq: true } }
    ) {
      edges {
        node {
          ...datoCmsPageTemplateVideoDetail
        }
      }
    }
    blogs: allDatoCmsBlogPost(
      sort: { fields: meta___publishedAt, order: DESC }
      filter: { isFeaturePriority: { eq: true } }
    ) {
      edges {
        node {
          ...datoCmsBlogPost
        }
      }
    }
  }
`;

export default ResourcesSliderCardDeck;
