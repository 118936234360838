import styled, { css } from 'styled-components';
import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';

export const sectionStyles = css`
  overflow: hidden;
  background: ${colors.gray[100]};
  padding-bottom: 0px;

  ${atMinWidth.sm`
    padding-bottom: 64px;
  `}
`;

export const innerStyles = css`
  && {
    padding-top: 48px;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const Header = styled.div`
  width: 100%;
  max-width: 817px;
  height: auto;
  padding: 0px 23.5px;
  margin-bottom: 32px;

  ${atMinWidth.xs`
    max-width: 1240px;
    padding: 0px 35px;
    margin: 0px auto 32px;
  `}

  h2 {
    ${font('display', 'md', '700')}
  }
`;

export const SliderWrapper = styled.div`
  width: 100vw;
  max-width: 100vw;
  position: relative;
  top: 0px;
  overflow: visible;
`;

export const sliderStyles = css`
  position: relative;
  cursor: grab;
  margin: 0px 23.5px;

  ${atMinWidth.sm`
    margin: 0px 0px 0px 35px;
  `}

  .slick-slider .slick-track, .slick-slider .slick-list {
    transform: none;
    padding-bottom: 16px;
  }

  .slick-initialized {
    .slick-track {
      visibility: visible;
    }
  }

  .slick-track {
    display: flex;
    transform: none;
    visibility: hidden;

    ${atMinWidth.sm`
      max-width: 100vw;
    `}
  }

  .slick-slide > div {
    height: 100% !important;
  }

  .slick-slide {
    width: 100%;
    height: inherit !important;

    ${atMinWidth.sm`
      width: 370px;
      margin-right: 30px;
    `}
  }

  .slider-controls {
    margin-top: 16px;

    ${atMinWidth.sm`
      margin-bottom: -48px;
    `}
  }

  .resource-card {
    width: 100%;
    height: 100%;

    ${atMinWidth.sm`
      width: 370px;
    `}
  }
`;
