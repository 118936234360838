import styled from 'styled-components';
import { colors } from '../../../styles/atoms/colors';
import shadow from '../../../styles/atoms/shadows';
import { font } from '../../../styles/atoms/typography';

export const ResourceCardStyle = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  background-color: ${colors.base.white};
  border-radius: 12px;
  overflow: hidden;
  ${shadow('xl')}
  cursor: pointer;
`;

export const Thumbnail = styled.div`
  position: relative;
  width: 100%;
  height: 290px;
  overflow: hidden;
  flex-shrink: 0;

  .gatsby-image-wrapper {
    width: 100%;
    height: 290px;
    display: block;
    overflow: hidden;
  }

  img {
    display: block;
    position: absolute;
    inset: 0;
    object-fit: cover;
    object-position: center;
    transform-origin: center;
    transition: transform 300ms ease-in-out 0s !important;

    ${ResourceCardStyle}:hover & {
      transform: scale(1.1);
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  gap: 44px;
  padding: 24px;
  height: 100%;
  border-top: 0.25px solid ${colors.gray[200]};

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    /* Fixes custom Dato cards that don't use a footer */
    &:empty,
    &:has(${TagContainer}:empty:only-child) {
      display: none;
    }
  }
`;

export const TagContainer = styled.div`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
`;

export const Heading = styled.h3`
  ${font('display', 'sm', '700')};
  color: ${colors.gray[900]};
  margin-bottom: 12px;
`;

export const Excerpt = styled.div`
  ${font('text', 'sm', '400')};
  color: ${colors.gray[700]};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  > div :last-child {
    margin-bottom: 0;
  }
`;

export const MetaLabel = styled.div`
  ${font('text', 'xs', '400')};
  color: ${colors.gray[600]};
  white-space: nowrap;
  padding-left: 16px;
`;

export const Button = styled.button`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0px;
  z-index: 100;
  background: transparent;
  border: none;
  outline: none;
`;
