import React, { useState, useEffect, useRef } from 'react';
import ReactSlider from 'react-slick';
import styled from 'styled-components';

import Controls from './Controls';

import {
  defaultConfig,
  initializeSlider,
  hideOffscreenSlides,
} from './utils/helpers';

const Slider = ({ children, config, styles, hideOffSceenSlide }) => {
  const sliderRef = useRef(null);
  const [renderSlider, setRenderSlider] = useState(false);
  const sliderConfig = config || defaultConfig;

  useEffect(() => {
    initializeSlider(setRenderSlider, hideOffSceenSlide);
    if (hideOffSceenSlide) {
      hideOffscreenSlides();
    }
  }, []);

  if (!renderSlider) return null;
  return (
    <Container className="slider" styles={styles}>
      <ReactSlider ref={sliderRef} {...sliderConfig}>
        {children}
      </ReactSlider>
      <Controls sliderRef={sliderRef} showControls={sliderConfig?.controls} />
    </Container>
  );
};

export default Slider;

const Container = styled.div`
  ${props => props?.styles}
`;
