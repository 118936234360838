import styled, { css } from 'styled-components';

import { colors } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';
import { atMinWidth } from '../../../styles/atoms/breakpoints';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  inset: 0px;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  background: none;
  transition: all 400ms ease-in;
  ${props =>
    props.isOpen &&
    css`
      opacity: 1;
      pointer-events: all;
    `}
`;
export const Overlay = styled.button`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0px;
  background: rgba(0, 0, 0, 0.9);
  border: none;
  outline: none;
  padding: 0px;
  &:hover,
  &:focus {
    outline: none;
    border: none;
    background: rgba(0, 0, 0, 0.9);
  }
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 800px;
  margin: 0px auto;
  padding: 0px 15px;
  z-index: 9999;
  opacity: 0;
  transform: translateY(-100px);
  transition: all 400ms ease-in;
  ${props =>
    props.isOpen &&
    css`
      opacity: 1;
      transform: translateY(0px);
    `}
`;
export const Exit = styled.button`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  padding: 0px;
  font-size: 16px;
  margin-bottom: 24px;
  background: transparent;
  border: none;
  outline: none;
  opacity: 1;
  transform: translateX(0px);
  transition: opacity 400ms ease;
  &:hover {
    opacity: 0.5;
    background: none;
  }
  ${atMinWidth.lg`
    transform: translateX(30px);
  `}
`;

export const Video = styled.div`
  display: flex;
  width: 100%;
  aspect-ratio: 16/9;
  margin-bottom: 34px;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: auto;
  ${atMinWidth.md`
    flex-direction: row;
  `}
  && .video-modal-cta {
    width: 100%;
    ${atMinWidth.sm`
      width: auto;
    `}
  }
`;
export const Details = styled.div`
  width: 100%;
  max-width: 500px;
  height: auto;
  margin-bottom: 16px;
  ${atMinWidth.md`
      margin-bottom: 0px;
  `}
`;
export const Icon = styled.div`
  margin-bottom: 16px;
  .gatsby-image-wrapper {
    width: 100%;
    max-width: 150px;
    && img {
      object-fit: contain;
    }
  }
`;
export const Heading = styled.p`
  ${font('text', 'lg', '700')}
  color: ${colors.base.white};
  margin-bottom: 8px;
  ${atMinWidth.sm`
    ${font('text', 'xl', '700')}
    margin-bottom: 8px;
  `}
`;
export const Subhead = styled.p`
  ${font('text', 'sm', '400')}
  color: ${colors.base.white};
  ${atMinWidth.sm`
    ${font('text', 'md', '400')}
  `}
`;
