import styled from 'styled-components';
import Link from '../../../utils/Link';

export const Clickable = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: none;
  z-index: 100;
  ${props => props.style}
`;

export const ClickableLink = styled(Link)`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: none;
  z-index: 100;
  ${props => props.style}
`;
