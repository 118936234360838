import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styles/atoms/colors';
import Icon from '../../styles/atoms/icons';

const Controls = ({ sliderRef, showControls }) => {
  if (!showControls) return null;
  return (
    <Container className="slider-controls">
      <button type="button" aria-label="Previous" onClick={() => sliderRef.current.slickPrev()}>
        <Icon id="FiArrowLeft" size={24} color={colors.gray[500]} />
      </button>
      <button type="button" aria-label="Next" onClick={() => sliderRef.current.slickNext()}>
        <Icon id="FiArrowRight" size={24} color={colors.gray[500]} />
      </button>
    </Container>
  );
};

export default Controls;

const Container = styled.div`
  display: flex;
  gap: 24px;
  
  button {
    width: 48px;
    height: 48px;
    line-height: 0;
    border: 1px solid black;
    background: ${colors.base.white};
    border: 1px solid ${colors.gray[200]};
    border-radius: 28px;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.5;
    }

    &:focus {
      outline: 2px dotted ${colors.gray[700]};
      outline-offset: 2px;
    }
  }
`;
