import { colors } from '../../../../styles/atoms/colors';

export const tagColor = {
  blog: {
    bg: colors.primary[50],
    text: colors.primary[500],
  },
  ebook: {
    bg: colors.purple[100],
    text: colors.purple[900],
  },
  'shop legend': {
    bg: colors.green[50],
    text: colors.green[700],
  },
  video: {
    bg: colors.aqua[50],
    text: colors.aqua[900],
  },
  'business tool': {
    bg: colors.orange[50],
    text: colors.orange[800],
  },
  'pdf resource': {
    bg: colors.red[50],
    text: colors.red[600],
  }
};
