import { render } from 'datocms-structured-text-to-plain-text';

export const formatBlogPost = blogPost => {
  const categoryArray = Array.isArray(blogPost.category)
    ? blogPost.category
    : [blogPost.category];
  const blogObj = {
    id: blogPost.id,
    url: `/blog/${blogPost.slug}` || '',
    name: blogPost.title || '',
    image: {
      gatsbyImageData: blogPost?.image?.gatsbyImageData,
      image: blogPost?.image?.url || '',
    },
    tags: [{ id: 'blog', name: 'Blog', level: 1 }, ...categoryArray],
    publishDate:
      (blogPost.publishDate && new Date(blogPost.publishDate).toISOString()) ||
      '',
    excerpt: blogPost.excerpt || blogPost.metaTags?.description || '',
    wordCount: JSON.parse(blogPost.contentWordCounter)?.words || '',
    isRecommended: blogPost.isFeaturePriority,
  };

  return blogObj;
};

export const formatCaseStudy = caseStudy => {
  const contentWordCount = JSON.parse(caseStudy.contentWordCounter)?.words || 0;
  const aboutCompanyWordCount =
    JSON.parse(caseStudy.aboutCompanyWordCounter)?.words || 0;
  const caseObj = {
    id: caseStudy.id,
    url: `/case-studies/${caseStudy.slug}` || '',
    name: caseStudy.title || '',
    image: {
      gatsbyImageData: caseStudy?.thumbnail?.gatsbyImageData,
      image: caseStudy?.thumbnail?.url || '',
    },
    tags: [
      { id: 'caseStudy', name: 'Shop Legend', level: 1 },
      ...caseStudy.tag,
    ],
    publishDate:
      (caseStudy.publishDate &&
        new Date(caseStudy.publishDate).toISOString()) ||
      '',
    excerpt: caseStudy.excerpt || '',
    wordCount: contentWordCount + aboutCompanyWordCount,
    isRecommended: caseStudy.isRecommended,
  };

  return caseObj;
};

export const formatDatoResource = datoResource => {
  const resourceObject = {
    id: datoResource.id,
    url: `/resources/${datoResource.slug}` || '',
    name: datoResource.title || '',
    image: {
      gatsbyImageData: datoResource?.featuredImage?.gatsbyImageData,
      image: datoResource?.featuredImage?.url || '',
    },
    tags:
      datoResource.tags.length > 0
        ? [
            {
              id: datoResource.tags[0].name,
              name: datoResource.tags[0].name,
              level: 1,
            },
          ]
        : [],
    publishDate:
      (datoResource.publishDate &&
        new Date(datoResource.publishDate).toISOString()) ||
      '',
    excerpt: datoResource.excerpt || datoResource.metaTags?.description || '',
    wordCount: '',
    isRecommended: datoResource.isRecommended,
  };

  return resourceObject;
};

export const formatVideoTemplate = page => {
  const {
    content,
    excerpt,
    id,
    isRecommended,
    meta,
    metaLabel,
    showInModal,
    slug,
    tags,
    title,
    video,
  } = page;

  const videoObj = {
    id,
    url: showInModal ? video.youtubeUrl : `/video/${slug}` || '',
    name: title || '',
    image: {
      gatsbyImageData: video?.videoThumbnail?.image?.gatsbyImageData,
      image:
        video?.videoThumbnail?.image?.url ||
        '/images/Shopmonkey_Opengraphimage.png',
    },
    tags: [{ id: 'video', name: 'Video', level: 1 }, ...tags],
    publishDate: (meta.createdAt && new Date(meta.createdAt)) || '',
    excerpt: excerpt || render(content),
    metaLabel: metaLabel || 'Watch Full Video',
    showInModal,
    isRecommended,
  };

  return videoObj;
};
